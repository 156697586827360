import React from 'react';

import SEO from '../components/seo';

export default () => (
	<>
		<SEO title="404: Not found" />
		<h1>NOT FOUND</h1>
	</>
);
